import PropTypes from "prop-types";
import React from "react";

import Item from "./Item";


class Blog extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      num: 4
    }
    this.more_blogs = this.more_blogs.bind(this)
  }
  more_blogs(e) {
    e.preventDefault();
    let num = this.state.num
    this.setState({
      num: num
    })
  }
  render() {
    const { posts, theme } = this.props;

    return (
      <React.Fragment>
        <main className="main">
          <ul>
            {posts.map((post, index) => {
              const {
                node,
                node: {
                  fields: { slug }
                }
              } = post;
              if( index < this.state.num) return <Item key={slug} post={node} theme={theme} />;
            })}
          </ul>
            {/* <a href="#" onClick={(e) =>this.more_blogs(e)}>
              <div className="allow-button fa fa-angle-double-down">
              </div>
            </a> */}
        </main>

        {/* --- STYLES --- */}
        <style jsx>{`
          .main {
            padding: 0 ${theme.space.inset.default};
          }
          .allow-button {
            color: #989898;
            font-size: 45px;
            float: right;
            :hover {
              cursor: pointer;
            }
          }
          ul {
            list-style: none;
            margin: 0 auto;
            padding: ${`calc(${theme.space.default} * 1.5) 0 calc(${theme.space.default} * 0.5)`};
          }

          @above tablet {
            .main {
              padding: 0 ${`0 calc(${theme.space.default} * 1.5)`};
            }
            ul {
              max-width: ${theme.text.maxWidth.tablet};
            }
          }
          @above desktop {
            ul {
              max-width: ${theme.text.maxWidth.desktop};
            }
          }
          @below tablet {
            main {
              padding: 0px !important;
              ul {
                li {
                  padding-left: 0px !important;
                  padding-right: 0px !important;
                }
              }
            }
          }
        `}</style>
      </React.Fragment>
    );
  }
};

Blog.propTypes = {
  posts: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired
};

export default Blog;
