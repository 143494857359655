import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import { ThemeContext } from "../layouts";
import Blog from "../components/Blog";
import Seo1 from "../components/Seo/Seo1"
import Hero from "../components/Hero";
import Seo from "../components/Seo";
import { Link } from "gatsby";
import Category from "../components/Category/EachCategory"
import Slider from 'react-animated-slider';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-animated-slider/build/horizontal.css';
import 'normalize.css/normalize.css';
import { keywords } from "../../config"
import GoogleMapComponent from "../components/Contact/googlemap"
// import InfiniteCarousel from 'react-leaf-carousel';

var VV = ''
const content = [
	{
		title: 'Our Vision',
		description:
		'To be an Organization of Excellence that contributes to knowledge discovery and technology advancement.',
		button: 'Read More',
		image: '/assets/bannerOurVision-solar-ww1200.jpg',
		user: 'X2LAB',
		userProfile: ''
	},
	{
		title: 'Our Values',
		description:
		'Quality Products, Dedicated Services, Responsibility and Progress.',
		button: 'View',
		image: '/assets/banner-ourValues-v3-ww1200.jpg',
		user: 'X2LAB',
		userProfile: ''
	},
	{
		title: 'Our Products',
		description:
		'Lithium-ion Battery Research, Solar Simulation, Anaerobic Fermentation, and more... ',
		button: 'View Products',
		image: '/assets/bannerOurProducts-ww1200.jpg',
		user: 'X2LAB',
		userProfile: ''
	}
];

class IndexPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      category: [],
      client: [],
      keys: [

      ],
      special: ''
    }
    this.scroll = this.scroll.bind(this)
  }

  scroll(direction) {
    let far = $( '.image-container' ).width()/2*direction;
    let pos = $('.image-container').scrollLeft() + far;
    $('.image-container').animate( { scrollLeft: pos }, 1000)
  }

  componentDidMount() {
    import('react-leaf-carousel')
      .then((item) => {
        this.setState({
          special: item.default
        })
      })
    
    let category = [];
    let Order = [];
    let flag = []
    this.props.data.category.edges.map((item,key) => {
      const ca = item.node.frontmatter;
      category.push(ca);
      flag.push(0);
    })
    let pos = 0;
    for ( let i = 0 ; i < category.length ; i ++) {
      let Min = 1000
      for ( let j = 0 ; j < category.length ; j ++) {
        if (flag[j]) continue
        if (Min > Number(category[j].order)) {
          Min = Number(category[j].order)
          pos = j
        }
      }
      flag[pos] = 1;
      Order.push(category[pos])
    }

    this.setState({
      category: Order
    });
    let client = [];
    if (this.props.data.client !== null) {
      this.props.data.client.edges.map((item,key) => {
        const ca = item.node.frontmatter;
        client.push(ca)
      })
      this.setState({
        client: client
      });
    }
  }
  separator = React.createRef();

  scrollToContent = e => {
    this.separator.current.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  render() {
    const {
      data: {
        posts: { edges: posts = [] },
        bgDesktop: {
          resize: { src: desktop }
        },
        bgTablet: {
          resize: { src: tablet }
        },
        bgMobile: {
          resize: { src: mobile }
        },
        site: {
          siteMetadata: { facebook }
        }
      }
    } = this.props;
    var VV = this.state.special
    const backgrounds = {
      desktop,
      tablet,
      mobile
    };
    if (this.state.client.length === 0) {
      return(
        <div></div>
      )
    }
    return (
      <React.Fragment>
        <div className="slider-container-home">
          <Slider 
            className="slider-wrapper"
            infinite = 'true'
            autoplay = '5000'
            previousButton  = ''
            nextButton = ''
          >
            {content.map((item, index) => (
              <div
                key={index}
                className="slider-content"
                style={{ background: `url('${item.image}') no-repeat center center` }}
              >
                <div className="inner">
                  <h1>{item.title}</h1>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="products_blogs_section">
            <div className="products col-12 col-sm-12 col-sm-12 col-md-12 col-lg-12">
              <div className="home-products">
                <div className="category-title">
                  {'Our Products and Services'}
                </div>
                <div className="category-content">
                  {'We are one-stop supplier for Lithium-ion battery research related equipment and consumables. We also offer design and customization service for specific research needs, including engineering design service for IoT solutions in Smart City and Industrial automation. All inquiries are welcome. Please email us: sales@x2lab.com.sg, and we will send you a reply shortly.'}
                </div>
              </div>
              <Category
                status={1}
                item={this.state.category}
              />
            </div>
            <div className="blogs col-12 col-sm-12 col-sm-12 col-md-12 col-lg-12">
              <div className="category-title">
                {'Recent Articles'}
              </div>
              <ThemeContext.Consumer>
                {theme => <Blog posts={posts} theme={theme} />}
              </ThemeContext.Consumer>
            </div>
        </div>
        <div className="ourClient">
          <h2>OUR CLIENTS</h2>
          {/* <p>Just For Professinoal Battery Equipments</p> */}
          {
            VV === "" ?
            <div>
            </div>
            :
            <VV
            breakpoints={[
              {
                breakpoint: 500,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                },
              },
            ]}
            // dots={true}
            showSides={true}
            sidesOpacity={.5}
            sideSize={.1}
            slidesToScroll={4}
            slidesToShow={4}
            scrollOnDevice={true}
            animationDuration={1000}
            autoCycle={true}
            // cycleInterval={10000}
          >
            {
              this.state.client.map((client, index) => {
                return (
                  <div key={index}>
                    <img
                      alt=''
                      src={client.mainimage}
                    />
                  </div>
                )
              })
            }
            </VV>
          }
        </div>
        <div className="clientFeedbackContainer">
          <h2>CLIENT'S FEEDBACK</h2>
          <div className="border-bottom comments">
            <div className="comment-ava">
              <div className="img-info">
                <div className="i-i-c">
                  <img className="sum-img-c" src="/assets/phd-hat-ww200.jpg"/> 
                </div>
                <div className="i-i-c-i">
                  <div className="i-i-c-i-1">
                      Fudong (University of Marylanbd)
                  </div>
                  <div className="i-i-c-i-2">
                    2014.04.19
                  </div>
                </div>
              </div>
              <div className="banner">
                  <span className="d-1-i-g-i"><i className="fa fa-flag"></i></span>
              </div>
            </div>
            <div className="d-1-i-c d-1-i-c-b">
            I am glad to tell you the Swagelok cell works very well on my test for all-solid-state lithium ion batteries, and I am planning to buy some more for test. Probably you could also add this feature to the guiding instruction as an advertisement.
            </div>
          </div>
          
        </div>
        <div className="ourClient tags">
          <h2>Tags</h2>
          <div className="keywords-container">
            {
              keywords.map((item, index) => {
                return(
                  <div key={index} className="keywords">
                    <Link to={`/search?${item}`} className="a">
                      <span>{item}</span>
                    </Link>
                  </div>      
                )
              })
            }
          </div>
          <Seo1
            postTitle = {'Welcome to X2Lab, you reliable partner in research.'}
            postDescription = {'X2 Lab Pte Ltd Homepage.'}
            keywords = {[]}
          />
        </div>
        {/* <div className="ourClient map">
          <h2>Our location</h2>
          <GoogleMapComponent></GoogleMapComponent>
        </div> */}
        <style jsx>{`
          .slider-container-home {
          }
          .tags {
            .keywords-container {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-around;
            }
            .keywords {
              margin: 9px;
              margin-left: 0px;
              .a {
                border-radius: 5px;
                padding: 5px 12px;
                background: #f0f0f0;
                span {
                  color: #676767;
                  font-weight: 600;
                  font-size: 15px;
                }
              }
            }
            max-width: 75em;
            padding: 20px;
            margin: 0 auto;
          }
          .comments {
            padding: 20px;
            max-width: 50em;
            margin: 0 auto;
            .d-1-i-c-b {
              color: #676767;
              font-weight: 600;
              font-size: 15px;
            }
            .comment-ava {
              display: flex;
              justify-content: space-between;
              margin-bottom: 30px;
              margin-top: 15px;
              .img-info {
                  display: flex;
                  align-items: center;
                  .i-i-c {
                    margin-right: 20px;
                    .sum-img-c {
                      border-radius: 50%;
                      width: 50px;
                    }
                  }
                  .i-i-c-i-1 {
                    font-weight: 700;
                    font-size: 16px;
                    color: #676767;
                  }
                  .i-i-c-i-2 {
                    font-size: 15px;
                    color: #676767;
                  }
              }
            }
          }
          .border-bottom {
            border-bottom: 1px solid #d5d5d5;
          }
          .clientFeedbackContainer {
            margin-top: 90px;
            h2 {
              color: #0098e4;
              font-size: 24px;
              text-transform: uppercase;
              font-weight: 600;
              text-align: center;
              padding: 0px 10px;
              padding-bottom: 40px;
            }
          }
          .blogs {
            margin-top: 20px;
          }
          .category-title {
            padding-bottom: 20px;
            text-align: center;
            max-width: 42em !important;
            margin: 0 auto !important;
          }
          .home-products {
            max-width: 62em;
            margin: 0 auto;
          }
          .map {
            max-width: 95em;
            margin: 0 auto;
            margin-bottom: 70px !important;
          }
          .tags {
            margin-top: 50px;
            svg {
              fill: #052b6c;
              margin: 0 5px 0 0;
            }
            ul {
              margin: 0 auto;
              max-width: 900px;
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              list-style: none;
              li {
                color: #676767;
                font-weight: 600;
                font-size: 15px;
                margin: 10px 25px;
              }
            }
          }
          .clientFeedback {
            margin-bottom: 50px;
            background-position: center;
            background-image: url(https://cdn.discordapp.com/attachments/522386759535951888/534534339711467530/window_feedback.jpg);
            h2 {
              color: #0098e4;
              font-size: 24px;
              text-transform: uppercase;
              font-weight: 600;
              text-align: center;
              padding: 0px 10px;
              padding-top: 40px;
              padding-bottom: 40px;
            }
            p, span{
              max-width: 700px;
              margin: 0 auto;
              text-align: center;
              padding: 10px 10px;
              color: #fff !important;
              font-weight: 600 !important;
              font-size: 15px !important;
            }
          }
          .ourClient {
            margin-bottom: 50px;
            h2 {
              color: #0098e4;
              font-size: 24px;
              text-transform: uppercase;
              font-weight: 600;
              text-align: center;
              padding: 0px 10px;
              padding-bottom: 40px;
            }
            p {
              text-align: center;
              padding: 10px 10px;
              color: #676767 !important;
              font-weight: 600 !important;
              font-size: 15px !important;
            }
            .main {
              text-align: center;
              height: 100vh;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
            }
            
            .title {
             font-size: 42px;
            }
            
            
            .image {
              height: 100px;
              width: 100px;
              font-size: 25px;
              background: blue;
              margin: 10px;
              display: inline-block;
              line-height: 100px; 
            }
            
            
            .image-container {
              vertical-align: middle;
              display: inline-block;
              white-space: nowrap;
              overflow-x: auto;
              overflow-y: hidden;
              width: 50%;
            }
            
            .prev, .next {
              padding-top: 10px;
              padding-bottom: 10px;
              height: 100%;
              cursor: pointer;
              color: black;
              transition: 0.6s ease;
            }
            
            .next {
              margin-left: 5px;
            }
            
            .prev {
              margin-right: 5px;
            }
            
            .prev:hover, .next:hover {
              color: white;
              height: 100%;
              background-color: rgba(0,0,0,0.8);
            }
          }
          .products_blogs_section {
            // display: flex;
            // flew-wrap:wrap;
            max-width: 90em;
            margin: 0 auto;
            padding: 10px;
            padding-top: 50px;
            padding-bottom: 30px;
           .category-title {
              
              color: #0098e4;
              font-size: 24px;
              text-transform: uppercase;
              font-weight: 600;
            }
            .category-content {
              color: #676767;
              font-weight: 600;
              font-size: 16px;
            }
          }
          @below desktop {
            .products_blogs_section {
              flex-direction: column;
              padding-right: 0px;
              padding-left: 0px;
            }
          
          }
        `}</style>
      </React.Fragment>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default IndexPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query IndexQuery {
    client: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "client-item"}}}) {
      edges {
        node {
          id
          frontmatter {
            title
            mainimage
          }
        }
      }
    }
    category: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "category-item"}}}
      sort: { fields: [frontmatter___order], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            detail
            mainimage
            order
          }
        }
      }
    }
    posts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//posts/[0-9]+.*--/" } }
      sort: { fields: [fields___prefix], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            prefix
          }
          frontmatter {
            title
            category
            author
            cover {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 800, maxHeight: 360) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
    bgDesktop: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 1200, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgTablet: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 800, height: 1100, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgMobile: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 450, height: 850, quality: 90, cropFocus: CENTER) {
        src
      }
    }
  }
`;

//hero-background
